import React from "react";
import "./Project.scss";
import ProjectCard from "../ProjectCard/ProjectCard";
import data from "../../data/data";

function Project() {
  return (
    <div className="project" id="Projects">
      <div className="project-container">
        <p>Portfolio</p>
        <h1>Each project is a unique piece of development 🧩</h1>
        <div>
          <ProjectCard
            title={data.cryptoApp.title}
            desc={data.cryptoApp.desc}
            img={data.cryptoApp.img}
            live={data.cryptoApp.live}
            techstack={data.cryptoApp.techstak}
            gitLink={data.cryptoApp.gitLink}
          />
        </div>
      </div>
    </div>
  );
}

export default Project;
