import Navbar from "./components/Navbar/Navbar";
import Hero from "./components/HeroSection/Hero";
import './App.scss';
import Project from "./components/ProjectSection/Project";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import About from "./components/About/About";

function App() {
  return (
   <>
   <Navbar/>
   <Hero/>
   <About/>
   <Project/>
   <Contact/>
   <Footer/>
   </>
  );
}

export default App;
