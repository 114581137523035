import React from 'react';
import './Footer.scss';
import { AiFillGithub } from "react-icons/ai";
import {BsLinkedin} from 'react-icons/bs';

function Footer() {
  return (
    <footer>
        <div className="rights" id='Footer'>
        Copyright © 2023. All rights are reserved
        </div>
        <div className="icons">
            <a href="https://github.com/zishancoder" target='blank'><AiFillGithub/></a>
            <a href="https://www.linkedin.com/in/zishan-ahmad-86bb341a5/" target='blank'><BsLinkedin/></a>
        </div>
    </footer>
  )
}

export default Footer