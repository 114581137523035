import React from "react";
import "./ProjectCard.scss";
import { AiFillGithub } from "react-icons/ai";
import { BsBoxArrowUpRight } from "react-icons/bs";

function ProjectCard({title,desc,img,techstack=[],gitLink,live}) {
  return (
    <div className="project-card">
      <div className="project-img">
        <img src={img} alt="" />
      </div>
      <div className="project-desc">
        <h2>{title}</h2>
        <p>
          {desc}
        </p>
        <div className="tech-stack">
          {
            techstack.map((val,idx)=>(
              <li key={idx}>{val}</li>
            ))
          }
        </div>
        <div className="project-links">
            <a href={gitLink} target="blank">Code <AiFillGithub/></a>
            <a href={live} target="blank">Live Demo <BsBoxArrowUpRight/></a>
        </div>
      </div>
    </div>
  );
}

export default ProjectCard;
