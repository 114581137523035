import React from 'react';
import {RxHamburgerMenu,RxCross2} from 'react-icons/rx';
import './Navbar.scss';
import {Link} from 'react-scroll';
function Navbar() {

  const closeMenu = () => {
    const menu = document.querySelector('.menu');
    menu.classList.remove('menu-animation-open');
    menu.classList.add('menu-animation-close');
  } 

  const showMenu = () => {
    const menu = document.querySelector('.menu');
    menu.style.display='flex';
    menu.classList.remove('menu-animation-close');
    menu.classList.add('menu-animation-open');
  }

  return (
    <nav className='navbar'>
        <div className="logo">Zishan.dev</div>
        <div className="links">
            <Link to="Hero" spy={true} smooth={true} offset={50} duration={500}>Home</Link>
            <Link to="About" spy={true} smooth={true} offset={50} duration={500}>About</Link>
            <Link to="Projects" spy={true} smooth={true} offset={50} duration={500}>Projects</Link>
            <Link to="Contact" spy={true} smooth={true} offset={50} duration={500}>Contact</Link>
        </div>
        <div className='mobile-menu'>
            <RxHamburgerMenu onClick={showMenu}/>
            <div className="menu">
                <RxCross2 onClick={closeMenu}/>
                <Link to="Hero" spy={true} smooth={true} offset={50} duration={500} onClick={closeMenu}>Home</Link>
                <Link to="About" spy={true} smooth={true} offset={50} duration={500} onClick={closeMenu}>About</Link>
                <Link to="Projects" spy={true} smooth={true} offset={50} duration={500} onClick={closeMenu}>Projects</Link>
                <Link to="Contact" spy={true} smooth={true} offset={50} duration={500} onClick={closeMenu}>Contact</Link>
            </div>
        </div>
    </nav>
  )
}

export default Navbar