import React from "react";
import "./Contact.scss";
import {FaMapMarkedAlt} from 'react-icons/fa';
import {GoMailRead} from 'react-icons/go';

function Contact() {
  return (
    <div className="contact-section" id="Contact">
      <div className="contact-container">
        <p>CONTACT</p>
        <h1>Don't be shy! Connect Now! 👇</h1>
        <div className="contact-links">
          <div className="location">
            <div className="location-icon">
              <FaMapMarkedAlt/>
            </div>
            <div>
              <p>Location</p>
              <p>Dhanbad Jharkhand,India</p>
            </div>
          </div>
          <div className="mail">
            <div className="mail-icon">
              <GoMailRead/>
            </div>
            <div>
              <p>Mail</p>
              <a href="mailto:zishanahmad.rk@gmail.com">zishanahmad.rk@gmail.com</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
