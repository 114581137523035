import React from 'react';
import './Hero.scss';
import {BsLinkedin,BsGithub} from 'react-icons/bs';
import hand from '../../assets/waving.1.png';
import html from '../../assets/html.svg';
import css from '../../assets/css3.svg';
import js from '../../assets/javascript.svg'
import react from '../../assets/react.svg';
// import scss from '../../assets/scss.svg';

function Hero() {
  return (
    <section className="hero" id="Hero">
        <div className="container">
            <div className="container-left">
                <h1>Front-End React <br/>Developer <img src={hand} alt='hand'/></h1>
                <p>Hi, I'm Zishan Ahmad. A passionate Front-end React <br/>
                Developer based in Dhanbad Jharkhand,India.📍</p>
                <div className="icons">
                    <a href="https://www.linkedin.com/in/zishan-ahmad-86bb341a5/" target="_blank"  rel="noreferrer"><BsLinkedin/></a>
                    <a href="https://github.com/zishancoder" target="_blank"  rel="noreferrer"><BsGithub/></a>
                </div>
            </div>
            <div className="container-right">
                <div className="hero-img"></div>
            </div>
            <div className='hero-tech'>
                <h2>Tech Stack</h2>
                <li><img src={html} alt=""/></li>
                <li><img src={css} alt=""/></li>
                <li><img src={js} alt=""/></li>
                <li><img src={react} alt=""/></li>
                {/* <li><img src={scss} alt=""/></li> */}
            </div>
        </div>
     
    </section>
  )
}

export default Hero