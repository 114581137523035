import cryptoApp from '../assets/projectImages/cryptoApp.png';

const data = {
    aboutSection: {
        heading: "A dedicated Front-end Developer based in Dhanbad Jharkhand,India 📍",
        description: "I'm a Computer Science Final Year Student at BIT Deoghar, I possess an impressive arsenal of skills in HTML, CSS, JavaScript, React and SCSS. I excel in designing and maintaining responsive websites that offer a smooth user experience. My expertise lies in crafting dynamic, engaging interfaces through writing clean and optimized code and utilizing cutting-edge development tools and techniques. I am also a team player who thrives in collaborating with cross-functional teams to produce outstanding web applications."
    },
    cryptoApp:{
        title:"Crypto App",
        desc:"This App will give you an inclusive overview about Crypto currencies and their current market status",
        techstak:['React Js', 'Chakra UI'],
        img:cryptoApp,
        gitLink:"https://github.com/zishancoder/crypto-app",
        live:"https://zishan-cryptoapp.netlify.app/",
    },
}

export default data;