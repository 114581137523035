import React from "react";
import data from "../../data/data";
import "./About.scss";

function About() {
  return (
    <section className="about-section" id="About">
      <div className="about-container">
        <div className="img">
          <img src="https://images.unsplash.com/photo-1555066931-4365d14bab8c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8Y29kaW5nfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60" alt="" />
        </div>
        <div className="content">
          <p>ABOUT ME</p>
          <h1>{data.aboutSection.heading}</h1>
          <p>{data.aboutSection.description}</p>
        </div>
      </div>
    </section>
  );
}

export default About;
